import {createStore} from "vuex";
import team from "../Team/teamStore.js";
import teamMembers from "../Team/teamMemberStore.js";
import auth from "../Auth/authStore.js";
import users from "../Auth/userStore.js";
import outputProfiles from "../Plan/outputProfileStore.js";
import campaigns from "../Plan/campaignStore.js";
import advertisements from "../Plan/advertisementStore.js";
import notifications from "../Shared/notificationStore.js";

export default createStore({
    modules: {
        auth,
        users,
        team,
        teamMembers,
        outputProfiles,
        campaigns,
        advertisements,
        notifications,
    }
});
