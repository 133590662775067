import dayjs from 'dayjs';

export default {
    data() {
        return {
            errorMessages: {},  // Holds field-specific error messages returned by Laravel
        }
    },
    methods: {
        /**
         * @param {HTMLFormElement} form
         * @return {Promise<boolean>}
         */
        async validateForm(form) {
            this.errorMessages = {};

            const validation = await form.validate();

            return validation.valid
        },
        /**
         * Display Laravel's main and field-specific error messages.
         * @param {HTMLFormElement} form
         * @param {object} error
         * @return void
         */
        handleFormError(form, error) {
            if (error.response && error.response.data) {
                if (error.response.data.errors) {
                    this.setFieldErrorMessages(error.response.data.errors);
                }

                this.$notify.error(error.response.data.message);
            }

            form.validate();
        },
        /**
         * Set field-specific error messages.
         * @param {array} errors
         * @return void
         */
        setFieldErrorMessages(errors) {
            Object.keys(errors).forEach(key => {
                let fieldName = key;

                // When validating elements of arrays, Laravel indexes the error property in this format:
                // `field_name.{number}`. We just keep the field name.
                if (/\.\d+$/.test(key)) fieldName = key.split('.').shift();

                this.errorMessages[fieldName] = errors[key].shift().replace(`${key} `, '');
            })
        }
    },
    rules: {
        max(length) {
            return value => (value.length <= length) ? true : 'This value is too long';
        },
        min(length) {
            return value => (value.length >= length) ? true : 'This value is too short';
        },
        oneKeyOf(items) {
            return value => {
                return items[value] ? true : `'${value}' is not allowed here`;
            };
        },
        manyKeyOf(items) {
            return value => {
                if (!value) return true;

                value = Array.isArray(value) ? value : [value];

                for (let v = 0; v < value.length; v++) {
                    if (!items[value[v]]) {
                        return `'${value[v]}' is not allowed here`;
                    }
                }

                return true;
            };
        },
        isFuture(value) {
            const now = dayjs().format('YYYY-MM-DD');

            value = dayjs(value).format('YYYY-MM-DD');

            if (value >= now) return true;

            return 'This date cannot be in the past'
        },
        notBefore(value, limit) {
            if (!limit) return true;

            limit = dayjs(limit).format('YYYY-MM-DD');

            if (dayjs(value).format('YYYY-MM-DD') >= limit) return true;

            return `This date cannot be before ${limit}`
        },
        isEmail(value) {
            if (!value) return true;

            const regex = new RegExp(  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i);

            value = Array.isArray(value) ? value : [value];

            for (let v = 0; v < value.length; v++) {
                if (!regex.test(value[v])) {
                    return `'${value[v]}' is not a valid email`;
                }
            }

            return true;
        },
        required: (v) => !!v || 'This value is required'
    }
}
