export default async function guest({ next, store }) {
    //const storageItem = window.localStorage.getItem("guest");
    if (/*storageItem === "isNotGuest" &&*/ !store.getters['auth/user']) {
        await store.dispatch("auth/getAuthUser").then(() => {
            if (store.getters['auth/user']) {
                next({ path: "/dashboard" });
            } else {
                //store.dispatch("auth/setGuest", { value: "isGuest" });
                next();
            }
        }).catch(() => next());
    } else {
        next();
    }
}
