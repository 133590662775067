export function formatAsVuetifySelectOptions(list) {
    return Object.keys(list).map(key => {
        return {
            value: key,
            title: list[key],
        };
    });
}

export default {
    formatAsVuetifySelectOptions: formatAsVuetifySelectOptions,
}
