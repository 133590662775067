<template>
    <div class="view-output-profile-edit">
        <generic-view-title :title="(createMode ? 'Create a new' : 'Edit the') + ' output profile'">
            <v-btn type="submit"
                   @click.prevent="submitForm"
                   prepend-icon="spx:IconDeviceFloppy"
                   text="Save"/>
        </generic-view-title>

        <v-form ref="form">
            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <h5 class="mb-3">General</h5>
                <v-row class="pt-4">
                    <v-col cols="12">
                        <v-text-field
                            v-model="attr.name"
                            label="Name"
                            variant="outlined"
                            rounded="lg"
                            required
                            :rules="[slugServerError, $options.rules.required]"
                            @input="e => createMode ? checkSlugUniqueness(e, 'output_profiles') : null">
                        <template #details>
                            Folder name: {{ attr.slug }}
                        </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <h5 class="mb-3">Audio specification</h5>
                <v-row class="pt-4">
                    <v-col cols="3">
                        <v-select
                            v-model="attr.audio_channels"
                            :items="helpers.formatAsVuetifySelectOptions($options.constants.AUDIO_CHANNELS)"
                            :rules="[$options.rules.oneKeyOf($options.constants.AUDIO_CHANNELS), errorMessages.audio_channels]"
                            required
                            label="Audio channels"
                            variant="outlined"
                            rounded="lg"
                        ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            v-model="attr.volume_min"
                            type="number"
                            step="1"
                            required
                            label="Min Leq(m) volume"
                            variant="outlined"
                            rounded="lg"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            v-model="attr.volume_max"
                            type="number"
                            step="1"
                            required
                            label="Max Leq(m) volume"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete
                            v-model="attr.audio_lang"
                            :items="audioLanguages ? helpers.formatAsVuetifySelectOptions(audioLanguages) : []"
                            :rules="[$options.required, (audioLanguages ? $options.rules.manyKeyOf(audioLanguages) : true), errorMessages.audio_lang]"
                            required
                            chips
                            clear-on-select
                            label="Language"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <h5 class="mb-3">Video specification</h5>
                <v-row class="pt-4">
                    <v-col cols="3">
                        <v-select
                            v-model="attr.fps"
                            :items="helpers.formatAsVuetifySelectOptions($options.constants.VIDEO_FPS)"
                            :rules="[$options.rules.oneKeyOf($options.constants.VIDEO_FPS), errorMessages.fps]"
                            required
                            label="Frame rate"
                            variant="outlined"
                            rounded="lg"
                        ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select
                            v-model="attr.resolution"
                            :items="helpers.formatAsVuetifySelectOptions($options.constants.VIDEO_RESOLUTIONS)"
                            :rules="[$options.rules.oneKeyOf($options.constants.VIDEO_RESOLUTIONS), errorMessages.resolution]"
                            required
                            label="Resolution"
                            variant="outlined"
                            rounded="lg"
                        ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select
                            v-model="attr.aspect_ratio"
                            :items="helpers.formatAsVuetifySelectOptions($options.constants.VIDEO_ASPECT_RATIOS)"
                            :rules="[$options.rules.oneKeyOf($options.constants.VIDEO_ASPECT_RATIOS), errorMessages.aspect_ratio]"
                            required
                            label="Aspect Ratio"
                            variant="outlined"
                            rounded="lg"
                        ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete
                            v-model="attr.subtitle_lang"
                            :items="subtitleLanguages ? helpers.formatAsVuetifySelectOptions(subtitleLanguages) : []"
                            :rules="[(subtitleLanguages ? $options.rules.manyKeyOf(subtitleLanguages) : true), errorMessages.subtitle_lang]"
                            chips
                            clear-on-select
                            label="Subtitle language"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>

                </v-row>
            </v-sheet>

            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <h5 class="mb-3">DCP specification</h5>
                <v-row class="pt-4">
                    <v-col cols="3">
                        <v-text-field
                            v-model="attr.seconds_after"
                            type="number"
                            min="0"
                            step="0.3"
                            required
                            label="Append blank seconds"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>
                    <v-col cols="3">
                        <v-select
                            v-model="attr.dcp_bitrate"
                            :items="dcpBitrates ? helpers.formatAsVuetifySelectOptions(dcpBitrates) : []"
                            :rules="[(dcpBitrates ? $options.rules.oneKeyOf(dcpBitrates) : true), errorMessages.dcp_bitrate]"
                            required
                            label="Encoding bitrate"
                            variant="outlined"
                            rounded="lg"
                            @update:model-value="toggleKdmSwitch"/>
                    </v-col>
                    <v-col cols="3">
                        <v-select
                            v-model="attr.format"
                            :items="helpers.formatAsVuetifySelectOptions($options.constants.DCP_FORMATS)"
                            :rules="[$options.rules.oneKeyOf($options.constants.DCP_FORMATS), errorMessages.format]"
                            required
                            label="Format"
                            variant="outlined"
                            rounded="lg"
                            @update:model-value="toggleKdmSwitch"/>
                    </v-col>
                    <v-col cols="3">
                        <v-switch
                            id="output-profile-kdm"
                            v-model="attr.kdm"
                            label="Requires KDM"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-sheet class="pa-6 pb-0 mb-3" rounded>
                <h5 class="mb-3">Country and ratings</h5>
                <v-row class="pt-4">
                    <v-col cols="3">
                        <v-select
                            v-model="attr.country"
                            :items="countries ? helpers.formatAsVuetifySelectOptions(countries) : []"
                            :rules="[(countries ? $options.rules.oneKeyOf(countries) : true), errorMessages.country]"
                            required
                            label="Country"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>
                    <v-col cols="3">
                        <v-select
                            v-model="attr.rating"
                            :items="['U', 'PG', '12A', '15', '18']"
                            required
                            label="Rating"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>
                    <v-col cols="3">
                        <v-file-input
                            v-model="attr.rating_logo"
                            label="Rating logo (burn-in)"
                            prepend-icon=""
                            append-inner-icon="spx:IconCloudUpload"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>
                    <v-col cols="3">
                        <img v-if="rating_logo"
                             :src="rating_logo"
                             width="60" height="60"
                             alt="Rating logo"/>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-row class="mb-1">
                <v-col cols="12" class="text-center">
                    <v-btn type="submit"
                           @click.prevent="submitForm"
                           prepend-icon="spx:IconDeviceFloppy"
                           text="Save"/>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import {mapState} from "vuex";
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import AppConstants from "../../Application/constants.js";
import validationMixin from "../../Shared/Mixins/validationMixin.js";
import slugGenerationMixin from "../Mixins/slugGenerationMixin.js";
import formHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";
import types from "../../Shared/storeTypes.js";

const
    constants = AppConstants.plan.outputProfiles,
    defaults = {
        // meta
        name: '',
        slug: '',

        // audio
        audio_channels: constants.AUDIO_CHANNELS_DEFAULT,
        audio_sample_rate: constants.AUDIO_SAMPLE_RATE,
        audio_sample_depth: constants.AUDIO_SAMPLE_DEPTH,
        volume_min: constants.AUDIO_VOLUME_MIN,
        volume_max: constants.AUDIO_VOLUME_MAX,
        audio_lang: constants.AUDIO_LANG_DEFAULT,

        // video
        fps: constants.VIDEO_FPS_DEFAULT,
        resolution: constants.VIDEO_RES_DEFAULT,
        aspect_ratio: constants.VIDEO_ASPECT_RATIO_DEFAULT,
        subtitle_lang: [],

        // dcp
        seconds_after: 0,
        dcp_bitrate: constants.DCP_BITRATE,
        format: constants.DCP_FORMAT_DEFAULT,
        kdm: constants.DCP_KDM_DEFAULT,

        // rating & territory
        country: '',
        rating: null,
        rating_logo: '',
    };

export default {
    mixins: [validationMixin, slugGenerationMixin, formHelperMixin],
    constants: constants,
    components: {
        GenericViewTitle,
    },
    data() {
        return {
            attr: this.displayFormat(Object.assign({}, defaults)),
            rating_logo: null,
        }
    },
    computed: {
        ...mapState('outputProfiles', {
            audioLanguages: state => state[types.OUTPUT_PROFILES_DEFAULTS].audioLanguages,
            countries: state => state[types.OUTPUT_PROFILES_DEFAULTS].countries,
            subtitleLanguages: state => state[types.OUTPUT_PROFILES_DEFAULTS].subtitleLanguages,
            dcpBitrates: state => state[types.OUTPUT_PROFILES_DEFAULTS].dcpBitrates,
        }),
    },
    /**
     * Assign non-reactive data and fetch data from vuex/server.
     * @return void
     */
    created() {
        this.modelId = +this.$route.params.id;

        this.createMode = !this.modelId;

        if (!this.createMode) {
            this.fetchModel(this.modelId);
        }

        this.$store.dispatch('outputProfiles/fetchDefaults');
    },
    methods: {
        /**
         * Submit the form through the vuex store.
         * @returns void
         */
        async submitForm () {
            if (!await this.validateForm(this.$refs.form)) {
                return;
            }

            const http = this.createMode
                ? this.$store.dispatch('outputProfiles/store', this.attr)
                : this.$store.dispatch('outputProfiles/update', {id: this.modelId, attr: this.attr});

            http.then(data => this.attr = data)
                .then(data => {
                    this.submitMessage('Output profile', this.createMode);
                    this.modelId = data.id;
                    this.createMode = false;
                    this.rating_logo = data.rating_logo;
                })
                .catch(error => this.handleFormError(this.$refs.form, error));
        },
        /**
         * Fetch the model's attributes from the store/server.
         * @param {number} id
         * @returns void
         */
        fetchModel(id) {
            this.$store
                .dispatch('outputProfiles/get', id)
                .then(profile => {
                    this.attr = this.displayFormat(
                        Object.assign(this.attr, { ...profile})
                    );
                    this.rating_logo = profile.rating_logo;
                });
        },
        toggleKdmSwitch(value) {
            this.$el.querySelector('#output-profile-kdm')
                .disabled = value !== constants.DCP_FORMAT_SMPTE;
        },
        displayFormat(attr) {
            attr.fps = attr.fps.toString();
            attr.dcp_bitrate = attr.dcp_bitrate.toString();
            return attr;
        },
    }
}
</script>
