export default async function auth({ to, next, store }) {
    if (store.getters['auth/user']) {
        return next();
    }

    await store.dispatch('auth/getAuthUser')
        .then(() => next())
        .catch(response => {
            next({
                name: 'Login',
                query: {redirect: to.fullPath}
            });
        });
}
